export function isPC() {
  const userAgentInfo = navigator.userAgent
  const Agents = ['Android', 'iPhone',
    'SymbianOS', 'Windows Phone',
    'iPad', 'iPod']
  let flag = true
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
}

export function deviceType () {
  const u = navigator.userAgent
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
  const isIos = /Mac OS X/.test(navigator.userAgent) && !window.MSStream;
  if (isIos) {
    return 'ios'
  } else if(isAndroid){
    return 'android'
  } else {
    return u.match(/\(([^)]+)\)/)[1]
  }
}