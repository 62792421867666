<template>
  <div class="app-wrapper">
    <el-container>
      <el-header height="80px">
        <b-navbar toggleable="lg" type="dark" variant="info" class="app-navbar">
          <b-navbar-brand href="#" class="logo-nav">
            <img src="../assets/v3/common/bantusaku.png" alt="logo" class="logo">
          </b-navbar-brand>
          <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="app-navbar-nav" v-for="(item,index) in navList" :key="index">
              <b-nav-item @click="goRouter(item)" :class="{'nav-item-active': item.path == key}">{{ item.name }}</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
          <div class="ktb" @click="openKtb">
              <span>TKB90=100%</span><span class="el-icon-warning"></span>
          </div>
        </b-navbar>
        <div class="h-nav hidden-md-and-up">
           <div class="route-name">{{ routerName }}</div>
           <el-dropdown trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
              <img src="../assets/v3/common/gengduo.png">
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Layanan Pinjaman">Layanan Pinjaman</el-dropdown-item>
              <el-dropdown-item command="Pendanaan">Pendanaan</el-dropdown-item>
              <el-dropdown-item command="Tentang Kami">Tentang Kami</el-dropdown-item>
              <el-dropdown-item command="FAQ">FAQ</el-dropdown-item>
              <el-dropdown-item command="Blog">Blog</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-main>
        <router-view :key="key" />
      </el-main>
      <el-footer>
        <div class="footer-wrap">
          <div class="footer-content">
            <el-row :gutter="0">
              <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                <div class="footer-item">
                  <img  class="footer-logo" src="../assets/v3/home/bantusaku-footer.png" alt="bantusaku">
                  <iframe class="address-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.268961315148!2d106.8240139!3d-6.2282274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f34fe506ca41%3A0x8f1dec317017ccee!2sBantuSaku!5e0!3m2!1sid!2sjp!4v1708221235884!5m2!1sid!2sjp" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                <div class="footer-item">
                  <div class="footer-title">Alamat</div>
                  <div class="footer-text">PT Smartec Teknologi Indonesia</div>
                  <div class="footer-text">DEA Tower II, 11th Floor Suite 1101-1102</div>
                  <div class="footer-text">Kawasan Mega Kuningan Jl. Mega Kuningan Barat Kav. E4.3 No. 1-2</div>
                  <div class="footer-text">Jakarta Selatan 12950</div>
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                <div class="footer-item">
                  <div class="footer-title">Butuh Bantuan</div>
                  <div class="footer-text">Hubungi Kami di Hari Senin – Minggu Jam 09:00 – 17:30 WIB</div>
                  <div class="footer-text">Email: cs@bantusaku.id</div>
                  <div class="footer-text">Telepon: 1500-006</div>
                  <div class="footer-text">Whatsapp: 0889-1500-006</div>
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                <div class="footer-item">
                  <div class="footer-title">Follow Us On</div>
                  <div class="footer-img-card">
                    <a href="javascript:;" @click="footerGo('instagram')">
                      <svg-icon class="footer-svg" icon-class="instagram" />
                    </a>
                    <a href="javascript:;" @click="footerGo('twitter')">
                      <svg-icon class="footer-svg" icon-class="twitter" />
                    </a>
                    <a href="javascript:;" @click="footerGo('facebook')">
                      <svg-icon class="footer-svg" icon-class="facebook" />
                    </a>
                    <a href="javascript:;" @click="footerGo('linkedin')">
                      <svg-icon class="footer-svg" icon-class="linkedin" />
                    </a>
                    <a href="javascript:;" @click="footerGo('youtube')">
                      <svg-icon class="footer-svg" icon-class="youtube" />
                    </a>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="footer-statement-wrap">
            <div class="footer-statement">
            <div class="statement-title">Perhatian</div>
            <div class="item"><span class="letter">1.</span><span class="content">Layanan Pinjam Meminjam Berbasis Teknologi Informasi merupakan kesepakatan perdata antara Pemberi Pinjaman dengan Penerima Pinjaman, sehingga segala risiko yang timbul dari kesepakatan tersebut ditanggung sepenuhnya oleh masing-masing pihak.</span></div>
            <div class="item"><span class="letter">2.</span><span class="content">Risiko kredit atau gagal bayar ditanggung sepenuhnya oleh Pemberi Pinjaman. Tidak ada lembaga atau otoritas negara yang bertanggung jawab atas risiko gagal bayar ini.</span></div>
            <div class="item"><span class="letter">3.</span><span class="content">Penyelenggara dengan persetujuan dari masing-masing Pengguna (Pemberi Pinjaman dan/atau Penerima Pinjaman) mengakses, memperoleh, menyimpan, mengelola dan/atau menggunakan data pribadi Pengguna ("Pemanfaatan Data") pada atau di dalam benda, perangkat elektronik (termasuk smartphone atau telepon seluler), perangkat keras (hardware) maupun lunak (software), dokumen elektronik, aplikasi atau sistem elektronik milik Pengguna atau yang dikuasai Pengguna, dengan memberitahukan tujuan, batasan dan mekanisme Pemanfaatan Data tersebut kepada Pengguna yang bersangkutan sebelum memperoleh persetujuan yang dimaksud.</span></div>
            <div class="item"><span class="letter">4.</span><span class="content">Pemberi Pinjaman yang belum memiliki pengetahuan dan pengalaman pinjam meminjam, disarankan untuk tidak menggunakan layanan ini.</span></div>
            <div class="item"><span class="letter">5.</span><span class="content">Penerima Pinjaman harus mempertimbangkan tingkat bunga pinjaman dan biaya lainnya sesuai dengan kemampuan dalam melunasi pinjaman.</span></div>
            <div class="item"><span class="letter">6.</span><span class="content">Setiap kecurangan tercatat secara digital di dunia maya dan dapat diketahui masyarakat luas di media sosial.</span></div>
            <div class="item"><span class="letter">7.</span><span class="content">Pengguna harus membaca dan memahami informasi ini sebelum membuat keputusan menjadi Pemberi Pinjaman atau Penerima Pinjaman.</span></div>
            <div class="item"><span class="letter">8.</span><span class="content">Pemerintah yaitu dalam hal ini Otoritas Jasa Keuangan, tidak bertanggung jawab atas setiap pelanggaran atau ketidakpatuhan oleh Pengguna, baik Pemberi Pinjaman maupun Penerima Pinjaman (baik karena kesengajaan atau kelalaian Pengguna) terhadap ketentuan peraturan perundang-undangan maupun kesepakatan atau perikatan antara Penyelenggara dengan Pemberi Pinjaman dan/atau Penerima Pinjaman.</span></div>
            <div class="item"><span class="letter">9.</span><span class="content">Setiap transaksi dan kegiatan pinjam meminjam atau pelaksanaan kesepakatan mengenai pinjam meminjam antara atau yang melibatkan Penyelenggara, Pemberi Pinjaman dan/atau Penerima Pinjaman wajib dilakukan melalui escrow account dan virtual account sebagaimana yang diwajibkan berdasarkan Peraturan Otoritas Jasa Keuangan Nomor Nomor 10/POJK.05/2022 tentang Layanan Pinjam Meminjam Uang Berbasis Teknologi Informasi dan pelanggaran atau ketidakpatuhan terhadap ketentuan tersebut merupakan bukti telah terjadinya pelanggaran hukum oleh Penyelenggara sehingga Penyelenggara wajib menanggung ganti rugi yang diderita oleh masingmasing Pengguna sebagai akibat langsung dari pelanggaran hukum tersebut di atas tanpa mengurangi hak Pengguna yang menderita kerugian menurut Kitab Undang-Undang Hukum Perdata.</span></div>
          </div>
          </div>
        </div>
      </el-footer>
      <el-dialog
        :visible.sync="dialogVisible"
        custom-class="ktb-dialog">
        <!-- <div class="ktb-dialog-text">
          <div class="item"><span class="letter">a)</span><span class="content">TKB0 adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 0 (nol) hari kalender terhitung sejak jatuh tempo;</span></div>
          <div class="item"><span class="letter">b)</span><span class="content">TKB30 adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 30 (tiga puluh) hari kalender terhitung sejak jatuh tempo;</span></div>
          <div class="item"><span class="letter">c)</span><span class="content">TKB60 adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 60 (enam puluh) hari kalender terhitung sejak jatuh tempo; dan</span></div>
          <div class="item"><span class="letter">d)</span><span class="content">TKB90 adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 90 (sembilan puluh) hari kalender terhitung sejak jatuh tempo;</span></div>
        </div> -->
        <div class="ktb-dialog-content">
          <div>TKB 0=82.04%</div>
          <div>TKB 30=82.91%</div>
          <div>TKB 60=82.98%</div>
          <div>TKB 90=100%</div>
        </div>
      </el-dialog>
      <el-dialog
        :visible.sync="mDialogVisible"
        custom-class="m-ktb-dialog">
        <img class="safety-close" @click="mDialogVisible = false" src="../assets/v3/team/close-dialog.png" alt="close">
        <div class="m-ktb-dialog-content">
          <div>TKB 0=82.04%</div>
          <div>TKB 30=82.91%</div>
          <div>TKB 60=82.98%</div>
          <div>TKB 90=100%</div>
        </div>
        <!-- <div class="m-ktb-dialog-text">
          <div class="item"><span class="letter">a)</span><span class="content">TKB0 adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 0 (nol) hari kalender terhitung sejak jatuh tempo;</span></div>
          <div class="item"><span class="letter">b)</span><span class="content">TKB30 adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 30 (tiga puluh) hari kalender terhitung sejak jatuh tempo;</span></div>
          <div class="item"><span class="letter">c)</span><span class="content">TKB60 adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 60 (enam puluh) hari kalender terhitung sejak jatuh tempo; dan</span></div>
          <div class="item"><span class="letter">d)</span><span class="content">TKB90 adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 90 (sembilan puluh) hari kalender terhitung sejak jatuh tempo;</span></div>
        </div> -->
      </el-dialog>
    </el-container>
  </div>
</template>

<script>
import { isPC } from '../utils/auth'
export default {
  name: 'Layout',
  computed: {
    key() {
      return this.$route.path
    }
  },
  data(){
    return{
      navList:[
        {id: '1', name: 'Layanan Pinjaman',path: '/'},
        {id: '2', name: 'Pendanaan',path: '/lender'},
        {id: '3', name: 'Tentang Kami',path: '/about'},
        {id: '4', name: 'FAQ',path: '/faq'},
        {id: '5', name: 'Blog',path: '/blog'}
      ],
      dialogVisible: false,
      mDialogVisible: false,
      routerName: this.$route.name,
      clickId: sessionStorage.getItem('activeTab') ? sessionStorage.getItem('activeTab') : '1',
    }
  },
  methods:{
    openKtb(){
      if(isPC()){
        this.dialogVisible = true
      } else {
        this.mDialogVisible = true
      }
    },
    footerGo(type){
      switch(type){
        case 'instagram':
          window.open('https://www.instagram.com/bantusaku/')
          break;
        case 'twitter':
          window.open('https://twitter.com/bantusaku')
          break;
        case 'facebook':
          window.open('https://www.facebook.com/BantuSakuOfficial/')
          break;
        case 'linkedin':
          window.open('https://www.linkedin.com/search/results/all/?keywords=bantusaku&origin=RICH_QUERY_SUGGESTION&position=0&searchId=3baf7b83-2256-4b9d-b3be-f14769760057&sid=0tL')
          break;
        case 'youtube':
          window.open('https://www.youtube.com/channel/UCRvUc3WjppuDWChIusMK8lg')
          break;
      }
    },
    goRouter(item){
      this.clickId = item.id
      switch(item.id){
        case '1':
          this.$router.push('/')
          break;
        case '2':
          this.$router.push('/lender')
          break;
        case '3':
          this.$router.push('/about')
          break;
        case '4':
          this.$router.push('/faq')
          break;
        case '5':
          this.$router.push('/blog')
          break;
      }
    },
    handleCommand(e){
      this.routerName = e
      switch(e){
        case 'Layanan Pinjaman':
          this.$router.push('/')
          break;
        case 'Pendanaan':
          this.$router.push('/lender')
          break;
        case 'Tentang Kami':
          this.$router.push('/about')
          break;
        case 'FAQ':
          this.$router.push('/faq')
          break;
        case 'Blog':
          this.$router.push('/blog')
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-wrapper{
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  font-family: "Poppins-Regular";
  .safety-close{
    width: 24px;
    height: 24px;
    position: absolute;
    right: 10px;
    top: -36px;
    cursor: pointer;
  }
  .el-header{
    width: 100%;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: #fff;
    .app-navbar{
      height: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 0;
    }
    .navbar-toggler{
      background: #064939;
    }
    .bg-info{
        background-color: #fff !important;
    }
    .logo-nav{
      display: block;
      padding: 0;
    }
    .logo{
      width: 190px;
      height: 25px;
    }
    .navbar-collapse{
      height: 80px;
      line-height: 80px;
    }
    .ktb{
        font-size: 18px;
        color: #28543F;
        margin-left: 18px;
        border-radius: 30px;
        padding: 10px;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 7px 10px;
        border: 1px solid #28543F;
        span{
          margin-right: 4px;
          font-size: 14px;
        }
    }
    .app-navbar-nav{
      height: 50px;
      .nav-item{
        width: 160px;
        text-align: center;
        background-color: #fff;
        line-height: 50px;
        a{
          padding: 0;
          font-size: 14px;
          color: #000;
        }
      }
      .nav-item-active{
        background: rgba(216,216,216,0.29);
        border-radius: 30px;
        a{
          color: #28543F !important;
        }
      }
    }
    .h-nav{
      width: 100%;
      height: 50px;
      line-height: 50px;
      background: #145246;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      .route-name{
        font-size: 15px !important;
        font-weight: 600;
        padding-left: 24px;
      }
      .el-dropdown{
        width: 15%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ACDDB1;
        cursor: pointer;
        img{
          width: 20px;
        }
      }
    }
  }

  .m-ktb-dialog{
    background: #fff;
  }
  .ktb-dialog-text{
    font-size: 12px;
    color: #222222;
    .item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;
    }
    .letter {
      margin-right: 10px;
      flex-shrink: 0;
    }
    .content {
      text-align: justify;
    }
  }

  .m-ktb-dialog-text{
    font-size: 12px;
    color: #28543F;

    .item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;
    }
    .letter {
      margin-right: 10px;
      flex-shrink: 0;
    }
    .content {
      text-align: justify;
    }
  }
  .ktb-dialog-content{
    width: 100%;
    color: #28543F;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 15px 36px;
    box-sizing: border-box;
    margin-top: 1px;
  }
  .el-main{
    padding: 0;
    margin-top: 80px;
  }
  .el-footer{
    height: auto;
    padding: 0;
    margin: 0;
    .footer-wrap{
      width: 100%;
      min-height: 300px;
      background: #1D1D1D;
      padding-top: 80px;
      box-sizing: border-box;
      .footer-content{
        max-width: 1500px;
        min-height: 300px;
        margin: 0 auto;
        color: #fff;
        .footer-item{
          max-width: 240px;
          a{
            color: #fff !important;
          }
        }
        .footer-logo {
          margin-bottom: 25px;
        }
        .address-map{
          width: 260px;
        }
        .footer-title{
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 25px;
        }
        .footer-text{
          font-size: 12px;
          line-height: 17px;
          font-weight: 500;
          margin-bottom: 8px;
        }
        .footer-img-card{
          display: flex;
          align-items: center;
          .footer-svg{
            width: 22px;
            height: 22px;
            margin-right: 10px;
            cursor: pointer;
          }
          .footer-svg:hover{
              fill: #B3E872;
            }
        }
      }
      .footer-statement-wrap{
        width: 100%;
        background: #1D1D1D;
        padding: 20px 0 40px 0;
        box-sizing: border-box;
        color: #fff;
        .footer-statement{
          max-width: 1500px;
          min-height: 300px;
          margin: 0 auto;
          font-size: 12px;
          .statement-title{
            font-size: 24px;
            font-weight: bold;
          }
          div{
            margin-bottom: 10px;
          }
        }
        .item {
          display: flex;
          align-items: flex-start;
          margin-bottom: 10px;
        }

        .letter {
          margin-right: 10px;
          flex-shrink: 0;
        }

        .content {
          text-align: justify;
        }
      }
    }
  }
}

</style>
